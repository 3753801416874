import { jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import { StyledLabel } from './styles';
// ----------------------------------------------------------------------
const Label = forwardRef(({ children, color = 'default', variant = 'soft', startIcon, endIcon, sx, ...other }, ref) => {
    const theme = useTheme();
    const iconStyle = {
        width: 16,
        height: 16,
        '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
    };
    return (_jsxs(StyledLabel, { ref: ref, component: "span", ownerState: { color, variant }, sx: {
            ...(startIcon && { pl: 0.75 }),
            ...(endIcon && { pr: 0.75 }),
            ...sx,
        }, theme: theme, ...other, children: [startIcon && _jsxs(Box, { sx: { mr: 0.75, ...iconStyle }, children: [" ", startIcon, " "] }), children, endIcon && _jsxs(Box, { sx: { ml: 0.75, ...iconStyle }, children: [" ", endIcon, " "] })] }));
});
export default Label;
